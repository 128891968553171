html{
  height: 100%;
}
body {
  margin: 0;
  /*background:  linear-gradient(#1d2125bf, #000000c9),url(https://buelearning.hkbu.edu.hk/bupix/theme/login_bg.jpg);*/
  background: #000 !important;
  background-color: #fff;
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
  background-attachment: fixed;
  

}

.MuiDataGrid-root{
  border-top: 1px solid;
  border-color: rgb(255 255 255 / 12%) ;
}
.MuiDataGrid-columnsContainer{
  border-color: rgb(255 255 255 / 12%) !important;
}
.MuiDataGrid-root .MuiDataGrid-cell{
  border-color: rgb(255 255 255 / 12%) !important;
  
}
.MuiListItemIcon-root.drawer-list{
  min-width: auto !important
}
.MuiAppBar-root.MuiAppBar-positionFixed{
  bottom: 0;
  top: unset;
}
/* font family */
.font{
  font-family: 'Poppins', sans-serif  !important;
 }

 /* sizes */
 .size-13{
  font-size: 13px !important;
}
.size-14{
  font-size: 14px !important;
}

.size-15{
  font-size: 15px !important;
}

.size-16{
  font-size: 16px !important;
}

.size-17{
  font-size: 17px !important;
}

.size-18{
  font-size: 18px !important;
}

.size-19{
  font-size: 19px !important;
}

.size-20{
  font-size: 20px !important;
}

.size-21{
  font-size: 21px !important;
}

.size-22{
  font-size: 22px;
}
.size-23{
  font-size: 23px;
}
.size-24{
  font-size: 24px;
}
.size-25{
  font-size: 25px;
}
.fa-wrench{
  font-size: 45px;
}

 /* font weights */
 .weight-bold{
  font-weight: bold;
}
.weight-100{
  font-weight: 100;
}

.weight-200{
  font-weight: 200;
}

.weight-300{
  font-weight: 300;
}

.weight-400{
  font-weight: 400;
}

.weight-500{
  font-weight: 500;
}
.weight-600{
  font-weight: 600;
}

/* Colors*/
.color-black{
  color: #000;
}
.color-grey{
    color: #dddfe2;
}
.color-white{
  color: #fff !important;
}
.color-blue{
  color: #1b75bb;
}
.bg-blue{
  background-color: #1b75bb !important;
}
.card{
  background-color: rgb(39 40 42);
}
.allgames-section{
  background-color: rgb(39 40 42);
  transition: background 1s ease;
}
.allgames-section:hover{
  background: #5a6268;
}

/* Custom CSS*/
.menu-items{
  font-size: 14px !important;
}


.text-capital{
  text-transform: capitalize !important;
}

.boxshadow{
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}


.btn-nostyle{
  line-height: 0px;
  padding: 0px;
  color: #fff;
  background-color: inherit !important;
  font-size: 13px;
}


.login-box{
  box-shadow: -2px 15px 14px 11px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius:0.25rem 0 0 0.25rem
}
.input-container{
  width: 100%;
}
.inp-login{
  width: 100%;
  background-color: inherit;
  border:0px;
  border-bottom: 1px solid #ccc;
  padding: 10px 8px;
  padding-left: 0px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size:16px
}
.inp-login:focus{
  border:0px;
  border-bottom: 1px solid #ccc;
  outline:0px
}
button.login-btn{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  background-color: #1b75bb;
  color: #fff;
  text-transform: uppercase;
}
::placeholder{
  color: #fff;
}
.side-img{
  background-image: url("https://buelearning.hkbu.edu.hk/bupix/theme/loginbox_bg_hkbu_2.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
}
.eye-icon{
  position: absolute;
  right: 30px;
  top: 12px;
  cursor: pointer;
}
.dialog-styling{
  box-shadow: -2px 15px 14px 11px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%) !important; 
  background-color: rgb(39 40 42) !important;
}
.left-m5{
  left:-12px !important
}
.input-container Input{
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size:16px
}
.input-container Label{
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size:14px;
}
.input-container .help-block{
  color: #FF0000 ;
  font-family: 'Poppins',sans-serif;
  font-size: 12px;
  margin-left: 0px;
}
.alert-msg{
  font-family:'Poppins',sans-serif !important;
  font-size: 12px !important;

}
.text-success{
  color:#66bb6a !important
}
.text-danger{
  color:#f44336 !important
}

.cursor-pointer{
  cursor: pointer;
}
.btn{
  width: 100%;
}

a{
  text-decoration: none !important;
}

span.light-text{
  cursor: pointer;
}
Button.login-btn{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  background-color: #1b75bb;
  color: #fff;
}
/*Overriding Stuff*/
.css-1oht444-MuiInputBase-root-MuiInput-root:before{
  border-bottom:1px solid #dddfe2 !important
}
.css-ume8vi-MuiInputBase-input-MuiInput-input{
  color:#dddfe2
}
.MuiTableRow-root.css-7ir1ib-MuiTableRow-root{
  background-color: rgba(255, 255, 255, 0.16) !important;
}
.style-p p{
  font-size: 14px;
  color: #fff;
  font-family: 'Poppins',sans-serif;
}
.MuiTableRow-root.css-7ir1ib-MuiTableRow-root td{
  width: 15% !important;
  word-break:break-word;
}
.MuiDataGrid-columnHeaderTitle.css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
}
.MuiDataGrid-virtualScrollerRenderZone.css-uw2ren-MuiDataGrid-virtualScrollerRenderZone{
  font-family: 'Poppins';
  font-size: 14px;
  
}
p.MuiTablePagination-displayedRows.css-11ceysh-MuiTablePagination-displayedRows{
  display: inline-flex;
  align-self: flex-end;
    
}
.css-upajmk-MuiTablePagination-selectLabel{
  text-transform: capitalize;
  display: inline-flex;
  align-self: end;
  font-family: 'Poppins',sans-serif;
  font-size: 12px !important;
}
select#mui-885092623,select#mui-885092623 option,p.MuiTablePagination-displayedRows.css-11ceysh-MuiTablePagination-displayedRows{
  
  font-size: 12px !important;
  font-family: 'Poppins',sans-serif;
}

.badge-warning{
  background-color: #ffb74d  !important;
}
svg{
  color: #fff;
}





.sidebar{
  color:#0f0f0f
}
#toolbar{
  width: 100% !important;
}
.MuiToolbar-root {
  padding-left: 30px !important;
   padding-right: 30px !important; 
}

.mobile-table th,td{
  width: 33%;
}

@media (max-width: 600px){
  .MuiToolbar-root {
    padding-left: 4px !important;
    padding-right: 4px !important; 
  }
  .size-14{
    font-size: 12px !important;
  }
  
  .size-15{
    font-size: 13px !important;
  }

  }